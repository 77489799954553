import './App.css'
function App() {

	return (
		<div className="App">
			{/* <div>
				<a href="https://slack.com/openid/connect/authorize?scope=email openid profile&client_id=4969192089140.4978931391908&redirect_uri=https://1bec-3-34-139-137.ngrok-free.app/oauth&response_type=code">
					login
				</a>
			</div>
			<div>
				<a href="https://slack.com/openid/connect/authorize?scope=email openid profile&client_id=4969192089140.4978931391908&redirect_uri=https://d0d7-13-125-226-228.ngrok-free.app/oauth&response_type=code">
					enterprise login
				</a>
			</div> */}
			{/* <a href="https://1bec-3-34-139-137.ngrok-free.app/sign-in"> */}
			123
			<div
				onClick={() => {
					fetch('https://slackbot-dev.gracy.io/api/slack/signin', {
						method: 'GET',
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json',
						},
					}).then(res => console.log(res.json()))
          .then(res=>console.log(res))
				}}
			>
				<img
					alt="Add to Slack"
					height="40"
					width="139"
					src="https://platform.slack-edge.com/img/add_to_slack.png"
					srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
				/>
			</div>
			{/* </a> */}
			{/* <button
				onClick={() => {
					fetch('http://localhost:5000/', {
						method: 'POST',
					})
						.then(res => res.json())
						.then(res => console.log(res))
				}}
			>
				CLICK
			</button>
			<button
				onClick={() => {
					const paymentMethod = stripe.paymentMethods
						.create({
							type: 'card',
							card: {
								number: '4242 4242 4242 4242',
								exp_month: 5,
								exp_year: 2028,
								cvc: '464',
							},
							billing_details: {
								address: {
									line1: '123 Main St',
									line2: '101 1201',
									city: 'New York',
									state: 'NY',
									postal_code: '10001',
									country: 'US',
								},
							},
						})
						.then(res => console.log(res.id))
				}}
			>
				stripe
			</button> */}
		</div>
	)
}

export default App
